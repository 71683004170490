import React, { useState } from "react";
import { Modal } from "react-bootstrap";

import { Checkbox } from '@material-ui/core';

export function CommitDialog({ onHide, id }) {

    const [vop, setVop] = useState(false);
    const [rules, setRules] = useState(false);
    const [main, setMain] = useState(false);

    const onSubmit=()=>{
        onHide();
    }


    return (
        <Modal
            size="lg"
            show={true}
            onHide={onHide}
            aria-labelledby="example-modal-sizes-title-lg"

        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">Odsouhlasení smlouvy</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div>
                    <Checkbox onChange={(e) => setMain(e.target.checked)} checked={main} />
                    Souhlasím se smlouvou v plném rozsahu
                </div>

                <div>
                    <Checkbox onChange={(e) => setVop(e.target.checked)} checked={vop} />
                    Přečetl jsem si všeobecné obchodní podmínky
                </div>

                <div>
                    <Checkbox onChange={(e) => setRules(e.target.checked)} checked={rules} />
                    Přečetl jsem si provozní řád služby internetu
                </div>


            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    onClick={onHide}
                    className="btn btn-light btn-elevate"
                >
                    Zrušit
                </button>
                <> </>
                {vop && rules && <button
                    type="submit"
                    onClick={() => onSubmit()}
                    className="btn btn-primary btn-elevate"
                >
                    Souhlasím
                </button>}

            </Modal.Footer>
        </Modal>
    );
}
