import React, {useEffect, useState} from "react";

import {SERVICE_VOIP_URL} from "../apiUrl";

import axios from 'axios';

import ServiceVoip from "../widgets/ServiceVoip";

import PublicOutlinedIcon from '@material-ui/icons/Public';

import CallsForm from "../form/CallsForm";
import {assigned_type} from './assigned_types';

export default function ServiceInternetDetail({id, setDetailPage}) {

const [service, setService] = useState(0);

useEffect(()=>{
    axios.get(SERVICE_VOIP_URL+'/'+id, {id: id}).then((response)=>{
		    setService(response.data);
		});

},[])
    if (service==0) return null;
    if (typeof service.error !== 'undefined') return null;
    
    return <div className="row">
	    <div className="col-lg-4">
		<ServiceVoip service={service} detail={true} setDetailPage={setDetailPage}/>
		</div>
	
	
	<div className="col-lg-8">
	<div className="card card-custom gutter-b">
		
	<div className="card-header border-0 py-5">
	    <h3 className="card-title align-items-start flex-column">
		<span className="card-label font-weight-bolder text-dark">
		    Zařízení
		</span>
	    </h3>
	</div>
			<div className="card-body py-0">	
		<div className="table-responsive">
			 <table className="table table-head-custom table-vertical-center">
          <thead>
            <tr>
              <th></th>
              <th>Typ</th>
              <th>Sériové číslo</th>
              <th>MAC / číslo karty</th>
              <th>Typ evidence</th>
            </tr>
          </thead>
          <tbody>
            {service.devices.map((device, key)=>
              <tr key={key}>
                <td component="th" scope="row"><img style={{width: 50}} src={"https://netis.sattnet.cz/images/items/"+device.image}/></td>
                <td>{device.type}</td>
                <td>{device.sn}</td>
                <td>{device.mac}</td>
                <td>{assigned_type(device.assigned_type)}</td>
              </tr>
	      )}

          </tbody>
        </table>
		</div>
		</div>
	    </div>
	    
	    
	    {service.numbers.length>0?(
	   <div className="card card-custom gutter-b">
	    
	    <div className="card-header border-0 py-5">
	    <h3 className="card-title align-items-start flex-column">
		<span className="card-label font-weight-bolder text-dark">
		    Výpis hovorů
		</span>
	    </h3>
	    </div>
		<div className="card-body py-0">	
			    <CallsForm numbers={service.numbers}/>
		</div>
	    </div>
		):null}
	</div>
	
    </div>

}
