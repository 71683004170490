import React, {useEffect, useState} from "react";

import {USER_INVOICES_URL} from "../apiUrl";

import axios from 'axios';
import { Badge, Button, Spinner } from 'react-bootstrap'

import NumberFormat from 'react-number-format';

import ModalInvoice from '../widgets/ModalInvoice';

import VisibilityIcon from '@material-ui/icons/Visibility';

const get_status_color=(status)=>{
	switch (status) {
		case 'paid':
			return 'success';
		case 'non_paid':
			return 'danger';
		case 'non_paid_valid':
			return 'warning';
		default:
			return 'info';
	}
}

const get_status_text=(status)=>{
	switch (status) {
		case 'paid':
			return 'Zaplacená';
		case 'non_paid':
			return 'Po splatnosti';
		case 'non_paid_valid':
			return 'Nezaplacená';
		default:
			return 'Neznámý';
	}
}

export default function Billing() {

    const [years, setYears]=useState([]);
    const [year, setYear]=useState(false);
    const [invoices, setInvoices]=useState([]);
    const [show, setShow]=useState(false);
    const [invoiceId, setInvoiceId]=useState(false);
	const [loader, setLoader]=useState(false);
		    
    useEffect(()=>{
		setLoader(true);
		axios.get(USER_INVOICES_URL+'/get').then((response)=>{
		    setLoader(false);
		    var years=response.data;
		    if (years.length>0) {
			setYears(response.data);
			setYear(years[years.length-1]);
		    }
		    		    
		}).catch(()=>{setYears([]); setLoader(false); });

	},[]);
	
     useEffect(()=>{
	 if (year !== false) {
		setLoader(true);
	     axios.get(USER_INVOICES_URL+'/'+year, ).then((response)=>{
		    var invoices=response.data;
		    setInvoices(invoices);
			setLoader(false);
		}).catch(()=>{setInvoices([]); setLoader(false); });
	 }
     },[year])	
 

     
    return <>
	    <div className="row">
	    <div className="col-lg-12">
	<div className="card card-custom gutter-b">
		
	<div className="card-header border-0 py-5">
	    <div>
		{years.map((value, key)=>{
		    return <Button onClick={()=>setYear(value)} key={key} style={{marginRight: 5}} variant="info">{value}</Button>
		})}
		</div>
		
		{loader && <Spinner
			  variant="primary" 
              animation="border"
              role="status"
              aria-hidden="true"
            />}
		
	</div>	
	    
	<div className="card-body py-0">	
		<div className="table-responsive">
			 <table className="table table-head-custom table-vertical-center">
          <thead>
	  
            <tr>
              <th>Číslo faktury</th>
              <th>Datum vystavení</th>
	      <th>Splatnost</th>
	      <th>Stav</th>
	      <th style={{textAlign: 'right'}}>Částka</th>
	      <th style={{textAlign: 'right'}}>K úhradě</th>
	      <th></th>
            </tr>
	    </thead>
          <tbody>
	    {invoices.map((value, key)=>{
		const status=value.status;

	    return <tr key={key}>
		    <td component="th" scope="row" style={{verticalAlign: 'top'}}>{value.invoice_number}</td>
			<td>{value.create_date}</td>
			<td>{value.due_date}</td>
			<td><Badge variant={get_status_color(status)}>{get_status_text(status)}</Badge></td>
			<td style={{textAlign: 'right'}}><NumberFormat value={value.total} displayType={'text'} decimalScale={0} thousandSeparator={' '} suffix={' Kč'}/></td>
			<td style={{textAlign: 'right'}}><NumberFormat value={value.paid} displayType={'text'} decimalScale={0} thousandSeparator={' '} suffix={' Kč'}/></td>
			<td><a onClick={()=>{setShow(true); setInvoiceId(value.access_key)}}><VisibilityIcon/></a></td>
		    </tr>
	  })}
          </tbody>
        </table>
		</div>
	    </div>
	</div>
    </div>
    </div>
	<ModalInvoice show={show} setShow={setShow} invoiceId={invoiceId}/>
    </>
    
	
}
