import React, { useEffect, useState, useCallback } from "react";

import { USER_SERVICES_URL, USER_PROFILES_URL } from "../apiUrl";

import axios from 'axios';
import Badge from 'react-bootstrap/Badge'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import EmailForm from '../form/EmailForm';
import PhoneForm from '../form/PhoneForm';
import PasswordForm from '../form/PasswordForm';

import { useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import SVG from "react-inlinesvg";

import { ProfileEditDialog } from "./Dialog/ProfileEditDialog";

const get_interval = value => {
	switch (value) {
		case 1:
			return 'měsíční';
		case 3:
			return 'čtvrtletní';
		case 6:
			return 'pololetní';
		case 12:
			return 'roční';

	}
	return 'měsíční';
}

export default function Support() {

	const { user } = useSelector((state) => state.auth);
	const [value, setValue] = useState(0);
	const [profiles, setProfiles] = useState([]);
	const [editProfile, setEditProfile]=useState({open: false});

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleChangeIndex = () => {

	};

	const update=useCallback(()=>{
		axios.get(USER_PROFILES_URL).then(response => {
			setProfiles(response.data);
		})
	},[]);

	useEffect(() => {
		update();
	}, [update]);

	console.log(editProfile);
	return <div className="row">

		<div className="col-lg-6">
			<div className="card card-custom gutter-b">

				<div className="card-header border-0 py-5">
					<h3 className="card-title align-items-start flex-column">
						<span className="card-label font-weight-bolder text-dark">
							{user.surname} {user.firstname}
						</span>
					</h3>
				</div>

				<div className="card-body py-0">

					<List>
						<ListItem>
							<ListItemText primary="Datum narození" secondary={user.birthdate} />
						</ListItem>
						<Divider />
						<ListItem>
							<ListItemText primary="Adresa" secondary={user.address} />
						</ListItem>
						{profiles.map((profile, key) => <div key={key}>
							<Divider />
							<ListItem style={{ alignItems: 'flex-start', justifyContent: 'space-between', paddingTop: 20, paddingBottom: 20 }}>
							
								<div>
										<div>Číslo smlouvy</div>
										<small className="text-muted">{profile.client_number}</small>
									</div>

									<div>
										<div>Interval platby</div>
										<small className="text-muted">{get_interval(profile.pay_interval)}</small>
									</div>

								
									<div style={{ display: 'flex' }}>

										<div>
											<a
												title="Upravit"
												className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
												onClick={() => setEditProfile({open: true, profile: profile})}
											>
												<span className="svg-icon svg-icon-md svg-icon-primary">
													<SVG
														title="Upravit"
														src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
													/>
												</span>
											</a>
										</div>
										<div>
											<div>Fakturační email</div>
													{profile.email.split(';').map((email, key)=><div key={key}><small className="text-muted">{email}</small></div>)}
													
										</div>
									</div>
								
							</ListItem>
						</div>)}

						

					</List>
				</div>
			</div>
		</div>

		<div className="col-lg-6">
			<div className="card card-custom gutter-b">
				<div className="card-body py-0">

					<Tabs
						value={value}
						onChange={handleChange}
						indicatorColor="primary"
						textColor="primary"
						variant="fullWidth"
					>
						<Tab label="Email" />
						<Tab label="Telefon" />
						<Tab label="Heslo" />

					</Tabs>

					<SwipeableViews
						axis="x-reverse"
						index={value}
						onChangeIndex={handleChangeIndex}
					>
						<div index={0} style={{ marginTop: 40, overflow: 'hidden' }}><EmailForm /></div>
						<div index={1} style={{ marginTop: 40, overflow: 'hidden' }}><PhoneForm /></div>
						<div index={2} style={{ marginTop: 40, overflow: 'hidden' }}><PasswordForm /></div>


					</SwipeableViews>

				</div>
			</div>
		</div>
		{editProfile.open && <ProfileEditDialog profile={editProfile.profile} onSuccess={()=>{update(); setEditProfile({open: false}) }} onHide={()=>setEditProfile({open: false})}/>}
	</div>

}
