import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link, useLocation, Redirect } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { register } from "../_redux/authCrud";
import {PASSWORD_RECOVERY_SUBMIT_URL} from "../../../apiUrl";
import axios from 'axios';

const initialValues = {
  password: "",
  changepassword: "",
  acceptTerms: true,
};

function Registration(props) {
  const rk = new URLSearchParams(useLocation().search).get('rk');
  
  const [isRecovered, setIsRecovered] = useState(false);
  
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError]=useState(false);
  const RegistrationSchema = Yup.object().shape({
     password: Yup.string()
      .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Heslo musí obsahovat minimálně jedno velké písmeno, jedno číslo a jeden speciální znak: !@#$%^&*()-_=+{};:,<.>"
	)
      .required(
        'Tato položka je vyžadována'
      ),
    changepassword: Yup.string()
      .required('Tato položka je vyžadována')
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Zadaná hesla nesouhlasí"
        ),
      })
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      enableLoading();
       axios.put(PASSWORD_RECOVERY_SUBMIT_URL, {rk: rk, password: values.password})
        .then(() => {
          //props.register(accessToken);
	  setIsRecovered(true);
        })
        .catch(() => {
          setSubmitting(false);
          setStatus(
            'Obnova hesla se nezdařila, zkuste to prosím později'
          );
          setError(true);
          disableLoading();
        });
    },
  });
  
  if ((rk == null) || (rk.length != 40)) return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.REGISTER.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          Neplatný odkaz
        </p>
      </div>
      </div>
      )

      if (isRecovered) return  <div className="login-form login-forgot" style={{ display: "block" }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">Nastavení hesla</h3>
        <div className="text-muted font-weight-bold">
            Heslo bylo úspěšně nastaveno
        </div>
      </div>
      <div className="form-group d-flex flex-wrap flex-center">
                  <Link to="/auth">
                    <button
                      type="button"
                      id="kt_login_forgot_cancel"
                      className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                    >
                      Přihlášení
                    </button>
                  </Link>
                </div>
      </div>    
  
  return <div className="login-form login-signin" style={{ display: "block" }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          Nastavení hesla
        </h3>
        <p className="text-muted font-weight-bold">
          Heslo musí obsahovat minimálně jedno velké písmeno, jedno číslo a jeden speciální znak.
        </p>
      </div>

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}


        {/* begin: Password */}
        {!error && <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Nové heslo"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>}
        {/* end: Password */}

        {/* begin: Confirm Password */}
        {!error && <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Potvrzení hesla"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "changepassword"
            )}`}
            name="changepassword"
            {...formik.getFieldProps("changepassword")}
          />
          {formik.touched.changepassword && formik.errors.changepassword ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.changepassword}
              </div>
            </div>
          ) : null}
        </div>}
        {/* end: Confirm Password */}

      
        <div className="form-group d-flex flex-wrap flex-center">
          {!error && <button
            type="submit"
            disabled={
              formik.isSubmitting ||
              !formik.isValid ||
              !formik.values.acceptTerms
            }
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>Nastavit</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>}

          <Link to="/auth/login">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
            >
              Storno
            </button>
          </Link>
        </div>
      </form>
    </div>
    
}

export default injectIntl(connect(null, auth.actions)(Registration));
