import React, {useEffect, useState} from "react";
import {SERVICE_VOIP_CALLS_URL} from "../apiUrl.js";
import axios from 'axios';
import { useSelector } from "react-redux";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

import clsx from "clsx";
import { Formik } from "formik";

import moment from 'moment/locale/cs';

export default function CallsForm(props) {

const {numbers}=props;
const [calls, setCalls]=useState(false);
const [loading, setLoading] = useState(false);
const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });
  
  
const months=[1,2,3,4,5,6,7,8,9,10,11,12];
var date=new Date();
const current_year=date.getFullYear();
const current_month=date.getMonth()+1;
var years=[];
for (let y=current_year-4; y<=current_year; y++) {
    years.push(y);
}

useEffect(()=>{
   //moment.locale('cs'); 
},[]);
	
const onSubmit=(values, {setSubmitting})=>{
     
    axios.post(SERVICE_VOIP_CALLS_URL, {id_number: values.id_number,
				    month: values.month,
				    year: values.year})
	    .then((response)=>{
		setSubmitting(false);
		if (response.data.error) {
			setCalls(false);
		    } else {
			//console.log(response);
			setCalls(response.data);
		    }
		}).catch(error => {
		    setSubmitting(false);
		    setCalls(false);
		});
    };

    console.log(calls);

    return (<>
	    <Formik
            initialValues={{
              id_number: numbers[0].id,
	      year: current_year,
	      month: current_month
            }}
            
            onSubmit={onSubmit}
          >
	  {({
              values,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
	      submitForm
            }) => (
		    <Form noValidate={true}
				autoComplete="off"
				className="kt-form"
				onSubmit={handleSubmit}
			      >
			<Form.Group as={Row}>
			    <Form.Label column sm={2}>
				Telefonní číslo:
			    </Form.Label>
			    <Col sm={2}>
				<Form.Control as="select" name="id_number" onBlur={handleBlur} onChange={handleChange} value={values.id_number}>
				    {numbers.map((number, key)=>{
					return <option key={key} value={number.id}>{number.number}</option>
				    })}
				</Form.Control>
			    </Col>
			     <Form.Label column sm={2} style={{textAlign: 'right'}}>
				Měsíc / rok:
			    </Form.Label>
			    <Col sm={2}>
				<Form.Control as="select" name="month" onBlur={handleBlur} onChange={handleChange}  value={values.month}>
				    {months.map((month, key)=>{
					return <option key={key} value={month}>{month}</option>
				    })}
				</Form.Control>
			    </Col>
			    <Col sm={2}>
				<Form.Control as="select" name="year" onBlur={handleBlur} onChange={handleChange} value={values.year}>
				    {years.map((year, key)=>{
					return <option key={key} value={year}>{year}</option>
				    })}
				</Form.Control>
			    </Col>
			    
			    <Col sm={2}>
			    <Button disabled={isSubmitting} onClick={()=>submitForm()} className={clsx(
				    {"kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": isSubmitting})}>
				    Zobrazit
				    </Button>
			    </Col>
				    
			    </Form.Group>
			</Form>
		)}
	    </Formik>
	    <Calls calls={calls}/>
	    </>	    
	)

}

function Calls(props) {
    const {calls}=props;
    //moment.locale('cs');
    if (calls===false) return null;
    if (typeof calls.calls === 'undefined') return false;
    return  <>
	    
	<div className="table-responsive">
	    
	 <table className="table table-head-custom table-vertical-center">
	    <thead>
            <tr>
              <th >Číslo</th>
              <th >Destinace</th>
              <th >Čas</th>
              <th style={{textAlign: 'right'}}>Délka hovoru</th>
              <th style={{textAlign: 'right'}}>Cena hovoru</th>
	      <th style={{textAlign: 'right'}}>Cena za min.</th>
            </tr>
          </thead>
          <tbody style={{overflow: 'auto'}}>
            {calls.calls.map((call, key)=>
              <tr key={key}>
                <td component="th" scope="row" style={{width: '13%'}}>{call.called}</td>
                <td >{call.destination}</td>
                <td >{ /** moment(call.start).format('L LT') */ call.start}</td>
                <td style={{textAlign: 'right'}} >{call.length} s</td>
                <td style={{textAlign: 'right'}}>{call.price} Kč</td>
		<td style={{textAlign: 'right'}}>{call.minute_price} Kč</td>
              </tr>
	      )}
         </tbody>
	 </table>
	 </div>
	 <p style={{fontWeight: 600, color: '#000000', marginTop: '1.5rem', textAlign: 'right'}}>Celková provolaná částka: {Number(calls.summary.price_sale).toFixed(2)} Kč ({calls.summary.length}s)</p>
	 </>
}
