import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    position: 'relative',
    top: 0
  }
});
console.log(useStyles);

const nazdar = 'nazdar';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function StatusMessage({state, message, type, onClose}) {
  
  const [open, setOpen] = useState(state);
  const classes=useStyles();
    
  
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    onClose();
  };

  return (
      <Snackbar anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
	 }}
	open={open}
	autoHideDuration={3000}
	onClose={handleClose}
	className={classes.root}
	>
        <Alert onClose={handleClose} severity={type}>
          {message}
        </Alert>
      </Snackbar>
      )
}