import React, {useEffect, useState} from "react";

import {USER_SERVICES_URL} from "../apiUrl";

import axios from 'axios';
import Badge from 'react-bootstrap/Badge'

import Divider from '@material-ui/core/Divider';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

export default function Support() {
	
	return <div>A tady taky snad..</div>
	
}
