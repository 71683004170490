import React, { useState, useContext, useEffect } from "react";

import { Modal } from "react-bootstrap";
import classes from './ProfileEditDialog.module.css';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from "axios";
import { USER_PROFILE_UPDATE_URL } from "../../apiUrl";

export function ProfileEditDialog({onHide, profile, onSuccess}) {
    
    const [emails, setEmails]=useState(profile.email.split(';'));
    const [checks, setChecks]=useState({});
    
    

const handleUpdate=()=>{
    var valid=true;

    const copy={};
    emails.map((email, key)=>{
        if (email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)) {
            copy[key]=true;
        } else {
            copy[key]=false;
            valid=false;
        }
        setChecks(copy);
    })
    
    if (valid)  { 
        const data_to_save={
            id: profile.id,
            email: emails.join(';')
        }
        axios.put(USER_PROFILE_UPDATE_URL, data_to_save).then(()=>{
            onSuccess();
        }).catch(e=>console.log(e.message));        
        
    }
}

const addEmail = () => {
    const copy = [...emails];
    copy.push('');
    setEmails(copy);
    //setSettings(copy);
    //updateData(copy);
  }

  const removeEmail = index => {
      const copy=[...emails];
      copy.splice(index, 1);
      setEmails(copy);
    //setSettings(copy);
    //updateData(copy);
  }

  const changeEmail = (value, index) => {
    const copy = [...emails];
    copy[index]=value;
    setEmails(copy);
    //setSettings(copy);
    //updateData(copy);
  }


    return <>
        <Modal
        dialogClassName={classes.ProfileEditDialog}
        show={true}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
    >
        <Modal.Header>
                <Modal.Title>Změna nastavení</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <div>
        <label>Zasílat faktury na následující emaily:</label>
      </div>

      {emails.map((email, key) =>
        <div key={key} style={{ display: 'flex', marginBottom: 2 }}>
          <div style={{flexGrow: 4}}>
            <input className={'form-control'+(checks[key]===false?' is-invalid':'')} type="text" value={email} onChange={(e) => changeEmail(e.target.value, key)} />
            {checks[key] === false && <div className="invalid-feedback">neplatná emailová adresa</div>}
          </div>

          <div style={{marginLeft: 2}}><button className="btn btn-danger btn-elevate" onClick={() => removeEmail(key)}>
            <DeleteIcon style={{ fontSize: '18px' }} />
          </button></div>
        </div>
      )}
      <button style={{ marginTop: 10 }} onClick={addEmail} className="btn btn-outline-secondary">
        <AddIcon style={{ fontSize: '18px', color: '#c9c9c9' }} />
      </button>
          
        </Modal.Body>
        <Modal.Footer>
        <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Zrušit
          </button>

          <button
            style={{ marginRight: 5 }}
            type="button"
            className="btn btn-primary"
            onClick={() => handleUpdate()}>
            Aktualizovat
          </button>
        </Modal.Footer>
        
    </Modal>
            
     </>
}
