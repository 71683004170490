import React, { useEffect, useState, useCallback } from "react";

import {
  SERVICE_INTERNET_URL,
  SERVICE_TV_URL,
  SERVICE_ITV_URL,
  SERVICE_TV_DEVICE_DELETE_URL,
  SERVICE_TV_DEVICE_NEW_URL,
  SERVICE_TV_SCHEDULER_URL,
  SERVICE_TV_SCHEDULER_NEW_URL,
  SERVICE_TV_SCHEDULER_DELETE_URL
} from "../apiUrl";


import axios from 'axios';

import ServiceTv from "../widgets/ServiceTv";

import PublicOutlinedIcon from '@material-ui/icons/Public';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { ConfirmDialog2 } from "../partials/ConfirmDialog2";
import StatusMessage from '../partials/StatusMessage';
import { Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { TvOfferEditDialog } from './Dialog/TvOfferEditDialog';

import { assigned_type } from './assigned_types';
import { RepeatOneSharp } from "@material-ui/icons";

const get_type = dtype => {
  switch (dtype) {
    case 'Mobile':
      return 'Mobilní telefon';
    case 'Browser':
      return 'Webový prohlížeč';
    default:
      return 'Neznámý';
  }
}

export default function ServiceTvDetail({ id, setDetailPage }) {

  const [service, setService] = useState(0);
  const [devices, setDevices] = useState([]);
  const [confirm, setConfirm] = useState({ open: false });
  const [confirmNew, setConfirmNew] = useState(false);
  const [updateStatus, setUpdateStatus] = useState({state: false, message: ''});
  const [loader, setLoader] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [scheduler, setScheduler] = useState(false);
  const [schedulerEdit, setSchedulerEdit] = useState(false);
  const [confirmCancelChanges, setConfirmCancelChanges] = useState(false);
  const [confirmNewScheduler, setConfirmNewScheduler]=useState(false);

  const get_scheduler=useCallback(()=>{
    axios.get(SERVICE_TV_SCHEDULER_URL + '/' + id)
    .then(response => {
      setScheduler(response.data);
    }).catch(setScheduler(false));
  },[]);

  useEffect(() => {
    get_scheduler();
  }, []);

  const newScheduler=()=>{
    setConfirmNewScheduler(false);
    axios.post(SERVICE_TV_SCHEDULER_NEW_URL+'/'+id)
    .then(()=>{
        get_scheduler();
    }).catch(()=>setUpdateStatus({state: 'error', message: 'V tuto chvíli není možné naplánovat změnu, kontaktujte prosím zákaznické centrum.'}));
  }

  const newDevice = () => {
    setSpinner(true);
    axios.post(SERVICE_TV_DEVICE_NEW_URL, { serviceId: id })
      .then(response => {
        if (response.data) {
          setUpdateStatus({state: 'success', message: 'Akce byla úspěšně provedena'});
          updateDev();
        } else {
          setUpdateStatus({state: 'error', message: 'Došlo k chybě, zkuste to prosím později.'});
        }
        setConfirmNew(false);
        setSpinner(false);
      })
      .catch(() => { setUpdateStatus({state: 'error', message: 'Došlo k chybě, zkuste to prosím později.'}); setConfirmNew(false) });
  }

  const cancelChanges = () => {
    setConfirmCancelChanges(false);
    axios.delete(SERVICE_TV_SCHEDULER_DELETE_URL + '/' + id)
      .then(()=>setScheduler(false))
      .catch(()=>{
        setUpdateStatus({state: 'error', message: 'Došlo k chybě, zkuste to prosím později.'});
      })
  }

  const updateDev = useCallback(() => {
    setLoader(true);
    axios.get(SERVICE_ITV_URL + '/' + id).then(response => {
      setDevices(response.data); setLoader(false);
    }
    ).catch(() => { setDevices([]); setLoader(false); });
  }, [])

  useEffect(() => {
    axios.get(SERVICE_TV_URL + '/' + id, { id: id }).then((response) => {
      setService(response.data);
      if (response.data.is_itv === 1) {
        updateDev();
      }
    });
  }, [updateDev])

  const deleteDevice = params => {
    setSpinner(true);
    axios.put(SERVICE_TV_DEVICE_DELETE_URL, { serviceId: id, deviceId: params.deviceId, backendDeviceId: params.backendDeviceId })
      .then(() => { setUpdateStatus({state: 'success', message: 'Akce byla úspěšně provedena'}); updateDev(); setConfirm({ open: false }); setSpinner(false); })
      .catch(() => { setUpdateStatus({state: 'error', message: 'Došlo k chybě, zkuste to prosím později.'}); setConfirm({ open: false }); setSpinner(false); });
  }

  if (service == 0) return null;
  if (typeof service.error !== 'undefined') return null;
  return <div className="row">
    <div className="col-lg-4">
      <ServiceTv service={service} detail={true} setDetailPage={setDetailPage} />
    </div>



    <div className="col-lg-8">
      <div className="card card-custom gutter-b">

        <div className="card-header border-0 py-5">
          <div>
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label font-weight-bolder text-dark">
                TV nabídky - detail
              </span>
            </h3>
          </div>
          <div>
            {scheduler === false ?
              <Button variant="primary" onClick={() => setConfirmNewScheduler(true)}>
                Naplánovat změnu
              </Button>
              :
              <div>
                <Button style={{ marginRight: 5 }} variant="success" onClick={() => setSchedulerEdit(true)}>
                  Změna nabídky od {scheduler.valid_from}
                </Button>
                <Button variant="danger" onClick={() => setConfirmCancelChanges(true)}>
                  Zrušit změny
                </Button>
              </div>}
          </div>
        </div>
        <div className="card-body py-0">
          <div className="table-responsive">
            <table className="table table-head-custom table-vertical-center">
              <thead>

                <tr>
                  <th>Balíček</th>
                  <th>Programy</th>
                </tr>

              </thead>
              <tbody>
                {service.packages_detail.map((tv_package, key) =>
                  <tr key={key}>
                    <td component="th" scope="row" style={{ verticalAlign: 'top' }}><span style={{ fontWeight: 600 }}>{tv_package.package_name}</span></td>
                    <td><TvChannels channels={tv_package.channels} /></td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>


      {service.is_itv && <div className="card card-custom gutter-b">

        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Interaktivní televize - mobilní zařízení / SMART TV
            </span>
          </h3>
          {loader ? <div style={{ paddingTop: 5 }}>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          </div> :
            <Button onClick={() => setConfirmNew(true)}>
              Přidat SMART TV
            </Button>
          }
        </div>

        <div className="card-body py-0">
          <div className="table-responsive">

            <table className="table table-head-custom table-vertical-center">
              <thead>
                <tr>
                  <th>Typ zařízení</th>
                  <th>Název / PIN</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {devices.map((device, key) => {
                  if (device.deviceTypeId === 0)
                    return <tr key={key}>
                      <td >{get_type(device.backendType)}</td>
                      <td >{device.backendName}</td>
                      <td ><a onClick={() => setConfirm({ open: true, deviceId: 0, backendDeviceId: device.backendDeviceId })}><DeleteOutlineIcon style={{ color: '#F64E60' }} /></a></td>
                    </tr>
                }
                )}

                {devices.map((device, key) => {
                  if (device.deviceTypeId === 12)
                    return <tr key={key}>
                      <td >SMART TV</td>
                      <td >{device.pin}</td>
                      <td ><a onClick={() => setConfirm({ open: true, deviceId: device.id, backendDeviceId: null })}><DeleteOutlineIcon style={{ color: '#F64E60' }} /></a></td>
                    </tr>
                }
                )}

              </tbody>
            </table>
          </div>
        </div>
        {updateStatus.state !== false ? <StatusMessage state={true} type={updateStatus.state} message={updateStatus.message} onClose={() => setUpdateStatus({state: false})} /> : null}
      </div>}


      <div className="card card-custom gutter-b">

        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Zařízení
            </span>
          </h3>
        </div>

        <div className="card-body py-0">
          <div className="table-responsive">

            <table className="table table-head-custom table-vertical-center">
              <thead>
                <tr>
                  <th></th>
                  <th>Typ</th>
                  <th>Sériové číslo</th>
                  <th>MAC / číslo karty</th>
                  <th>Typ evidence</th>
                </tr>
              </thead>
              <tbody>
                {service.devices.map((device, key) =>
                  <tr key={key}>
                    <td component="th" scope="row"><img style={{ width: 50 }} src={"https://netis.sattnet.cz/images/items/" + device.image} /></td>
                    <td >{device.type}</td>
                    <td >{device.sn}</td>
                    <td >{device.mac}</td>
                    <td >{assigned_type(device.assigned_type)}</td>
                  </tr>
                )}

              </tbody>
            </table>
          </div>

        </div>


      </div>

    </div>

    {schedulerEdit && <TvOfferEditDialog
      open={true}
      onHide={() => setSchedulerEdit(false)}
      onUpdate={()=> { setSchedulerEdit(false); get_scheduler(); }}
      offer={scheduler}
    />}

    {confirm.open && <ConfirmDialog2
      open={true}
      showSpinner={spinner}
      onClose={() => setConfirm({ open: false })}
      action={() => deleteDevice({ deviceId: confirm.deviceId, backendDeviceId: confirm.backendDeviceId })}
      message={'Odstranit vybrané zařízení?'} />}

    {confirmNew && <ConfirmDialog2
      open={true}
      showSpinner={spinner}
      onClose={() => setConfirmNew(false)}
      action={() => newDevice()}
      title={"Potvrzení volby"}
      message={'Přidat novou SMART TV?'}
    />}

  {confirmNewScheduler && <ConfirmDialog2
      open={true}
      showSpinner={spinner}
      onClose={() => setConfirmNewScheduler(false)}
      action={() => newScheduler()}
      title={"Potvrzení volby"}
      message={'Naplánovat změny v programové nabídce od následujícího měsíce?'}
    />}

    {confirmCancelChanges && <ConfirmDialog2
      open={true}
      onClose={() => setConfirmCancelChanges(false)}
      action={() => cancelChanges()}
      title={"Potvrzení volby"}
      message={'Zrušit naplánované změny?'}
    />}

  </div>

}



function TvChannels(props) {
  const { channels } = props;

  return channels.map((channel, key) => {
    return <div style={{ float: 'left', width: 200 }} key={key}>{channel}</div>;
  });

}
