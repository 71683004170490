import React, { useState, useContext, useEffect } from "react";

import { Modal } from "react-bootstrap";
import classes from './TvChannelEditDialog.module.css';
import { Checkbox } from '@material-ui/core';

import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

import axios from "axios";


export function TvChannelEditDialog({ onHide, channels_init, metadata, tv_package, onSubmit }) {


  const [channels, setChannels] = useState(channels_init);
  const [amount, setAmount] = useState(0);

  const handleUpdate = () => {
    onHide();
  }

  const change = (id, checked, group) => {
    const copy = { ...channels };
    if (checked) {
      copy[id] = true;
    } else {
      delete copy[id];
    }

    if (group) {
      metadata.custom_channels.map(channel => {
        if (channel.group === group) {
          if (checked) {
            copy[channel.id] = true;
          } else {
            delete copy[channel.id];
          }
        }
      })
    }
  
  setChannels(copy);
}

useEffect(() => {
  const count = metadata.custom_channels.length;
  let amount_temp = 0;
  for (let i = 0; i < count; i++) {
    const custom_channel = metadata.custom_channels[i];
    if (channels[custom_channel.id]) amount_temp += custom_channel.amount;
  }
  setAmount(amount_temp);
}, [channels]);


return <>
  <Modal
    dialogClassName={classes.TvChannelEditDialog}
    show={true}
    onHide={onHide}
    aria-labelledby="example-modal-sizes-title-lg"
  >
    <Modal.Header>
      <Modal.Title>Nastavení nabídky {tv_package.name}</Modal.Title>
      <div style={{fontSize: 20, color: (amount != tv_package.limit?'#F64E60':'#181C32')}}>{amount} / {tv_package.limit}</div>
    </Modal.Header>
    <Modal.Body>
      <div style={{ display: 'flex', flexWrap: 'wrap', height: 680, flexDirection: 'column'  }}>
        {metadata.custom_channels.map((channel, key) =>
          <div key={key} style={{ display: 'flex', alignItems: 'center' }}>
            <div>
              <Checkbox onChange={(e) => change(channel.id, e.target.checked, channel.group)} checked={channels[channel.id] ? true : false} />
            </div>

            <div style={{ width: 220, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: channel.group ? '#' + channel.group : 'auto' }}>
              {channel.name}
            </div>
          </div>
        )}
      </div>
      

    </Modal.Body>
    <Modal.Footer>
      <button
        type="button"
        onClick={onHide}
        className="btn btn-light btn-elevate"
      >
        Zrušit
      </button>

      <button
        disabled={amount != tv_package.limit}
        style={{ marginRight: 5 }}
        type="button"
        className="btn btn-primary"
        onClick={() => onSubmit(channels)}>
        Aktualizovat
      </button>
    </Modal.Footer>

  </Modal>

</>
}
