//const SITE = "https://netis.sattnet.cz";

const SITE = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')?'http://netisdev.satthosting.cz':'https://netis.sattnet.cz';

export const ME_URL = SITE+"/api/me";
export const LOGIN_URL = SITE+"/api/auth/login";
export const REGISTER_URL = SITE+"api/auth/register";
export const REQUEST_PASSWORD_URL = SITE+"/api/auth/forgot-password";
export const USER_URL = SITE+"/api/user";

export const USER_SERVICES_URL=SITE+"/api/user/services";
export const SERVICE_INTERNET_URL=SITE+"/api/service/internet";
export const SERVICE_TV_URL=SITE+"/api/service/tv";
export const SERVICE_TV_SCHEDULER_URL=SITE+"/api/service/tv/scheduler";
export const SERVICE_TV_SCHEDULER_DELETE_URL=SITE+"/api/service/tv/scheduler";
export const SERVICE_TV_SCHEDULER_UPDATE_URL=SITE+"/api/service/tv/scheduler";
export const SERVICE_TV_SCHEDULER_NEW_URL=SITE+"/api/service/tv/scheduler";
export const SERVICE_TV_DEVICE_DELETE_URL=SITE+"/api/service/itv/device/delete";
export const SERVICE_TV_DEVICE_NEW_URL=SITE+"/api/service/itv/device/insert";
export const SERVICE_TV_PASSWORD_UPDATE_URL=SITE+"/api/service/itv/password";
export const SERVICE_ITV_URL=SITE+"/api/service/itv";
export const SERVICE_VOIP_URL=SITE+"/api/service/voip";

export const USER_INVOICES_URL=SITE+"/api/user/invoices";
export const USER_PROFILES_URL=SITE+"/api/user/profiles";
export const USER_CONTRACTS_URL=SITE+"/api/user/contracts";
export const USER_PROFILE_UPDATE_URL=SITE+"/api/user/profile/update";

export const SERVICE_VOIP_CALLS_URL=SITE+"/api/service/voip/calls";

export const USER_UPDATE_DATA_URL=SITE+"/api/user/update";

export const PASSWORD_RECOVERY_URL=SITE+"/api/user/precovery";
export const PASSWORD_RECOVERY_SUBMIT_URL=SITE+"/api/user/precoverysubmit";


