
export const assigned_type=type=>{
    switch (type) {
        case 1:
            return 'Výdej';
        case 2:
            return 'Pronájem';
        case 3:
            return 'Prodej';
    }
    return ''
}