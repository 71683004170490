import React, { useEffect, useState } from "react";

import Badge from 'react-bootstrap/Badge'

import PublicOutlinedIcon from '@material-ui/icons/Public';

export default function ServiceInternet({ service, detail, setDetailPage }) {

	return <div className="card card-custom gutter-b ${!detail?'card-stretch':null}">



		<div className="card-body d-flex flex-column px-0">

			<div className="flex-grow-1 card-spacer-x mb-6">
				<div className="d-flex mb-6">
					<div className="mr-3" style={{ width: 80, height: 80, borderRadius: 40, backgroundColor: '#c91239' }}>
						<PublicOutlinedIcon style={{ fontSize: 70, margin: 5, color: 'white' }} />
					</div>
					<div>
						<div>
							<span className="font-size-h6 text-dark-75 text-hover-primary font-weight-bolder">
								Internet - {service.service_type}
							</span>
						</div>
						<div>
							<span className="text-dark-50 font-size-lg mt-2">
								{service.tariff_name}
							</span>
						</div>
						<div>
							{service.active == 1 ?
								<Badge variant="success" style={{ marginTop: 10 }}>Aktivní</Badge>
								:
								<Badge variant="danger" style={{ marginTop: 10 }}>Odpojeno</Badge>
							}
						</div>
					</div>
				</div>


				<div className="d-flex flex-wrap align-items-center justify-content-between w-100">
					<div className="font-weight-bolder">
						Adresa připojení:
					</div>
					<div >
						{service.address_street}
					</div>
				</div>

				<div className="d-flex flex-wrap align-items-center justify-content-between w-100">
					<div className="font-weight-bolder">
						Město / obec:
					</div>
					<div>
						{service.address_city}
					</div>
				</div>

				<div className="d-flex flex-wrap align-items-center justify-content-between w-100">
					<div className="font-weight-bolder">
						Rychlost:
					</div>
					<div>
						{service.download}/{service.upload} Mbps
					</div>
				</div>

				<div className="d-flex flex-wrap align-items-center justify-content-between w-100">
					<div className="font-weight-bolder">
						OKU:
					</div>
					<div>
						{service.oku}
					</div>
				</div>



			</div>

			<div className="flex-grow-1 card-spacer-x">

				<div className="kt-widget__footer">
					{!detail ?
						<a onClick={() => setDetailPage({ service: 'internet', id: service.id })} className="w-100 btn btn-primary font-weight-bold px-6 py-3">Detail</a>
						: <a onClick={() => setDetailPage(false)} className="w-100 btn btn-primary font-weight-bold px-6 py-3">Zpět na služby</a>}
				</div>
			</div>

		</div>
	</div>;

};

