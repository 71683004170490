import React, { useState, useContext, useEffect } from "react";

import { Modal } from "react-bootstrap";
import classes from './TvOfferEditDialog.module.css';
import { Checkbox } from '@material-ui/core';

import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { TvChannelEditDialog } from "./TvChannelEditDialog";
import SVG from "react-inlinesvg";

import {SERVICE_TV_SCHEDULER_UPDATE_URL } from "../../apiUrl";
import axios from "axios";

const fix_channels=channels=>{

  const pro_zacatek = 1;
  const rodina = 2;
  const volny_cas = 3;
  const sport_a_zabava = 4;
  const komplet = 12;
  const tematicka = 18;

  if (channels[tematicka]) {
    delete(channels[sport_a_zabava]);
    delete(channels[rodina]);
    delete(channels[volny_cas]);
  }

  if (channels[rodina] && channels[volny_cas] && channels[sport_a_zabava]) {
    delete(channels[sport_a_zabava]);
    delete(channels[rodina]);
    delete(channels[volny_cas]);
    channels[tematicka]=true;
  }

  if (channels[komplet]) {
    
    const new_channels = {};
    new_channels[komplet]=true;
    channels=new_channels;
    console.log(channels);
  } else {
    channels[pro_zacatek]=true;
  }
  return channels;
}

export function TvOfferEditDialog({ onHide, offer, onUpdate }) {


  const [packages, setPackages]=useState(offer.packages);
  const [edit, setEdit]=useState({open: false});
  const dis_changes=true;
  

  const handleUpdate = () => {
    const data_to_save={id: offer.id,
                      packages: packages};
    axios.put(SERVICE_TV_SCHEDULER_UPDATE_URL, data_to_save).then(()=>{
      onUpdate();
    }).catch(()=>console.log('error'));

  }

  const updateChannels=(channels, tv_package)=>{
    const copy={...packages};
    copy[tv_package.id]=channels;
    setPackages(copy);
    setEdit({open: false});
  }

  const change=(id, checked)=>{
    let copy={...packages};
    if (checked) {
      if (!copy[id]) copy[id]=[];
    } else {
      delete copy[id];
    }

    copy=fix_channels(copy);
    
    setPackages(copy);
  }

  
  return <>
    <Modal
      dialogClassName={classes.TvOfferEditDialog}
      show={true}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header>
        <Modal.Title>Změna programové nabídky od {offer.valid_from}</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        {offer.metadata.packages.map((tv_package, key) =>
          <div key={key} style={{ display: 'flex', alignItems: 'flex-start' }}>
            <div>
              <Checkbox disabled={dis_changes} onChange={(e) => change(tv_package.id, e.target.checked)} checked={packages[tv_package.id]?true:false} />
            </div>

            <div style={{width: 325, marginTop: 9}}>
              <div>
              {tv_package.name}  
              </div>
              <div>
                {offer.metadata.custom_channels.map((channel, key)=>
                  packages[tv_package.id] && packages[tv_package.id][channel.id] && <div style={{color: '#acacac', fontSize: 10 }} key={key}>{channel.name}</div>
                )}
              </div>
            </div>
            {tv_package.configurable===1 && packages[tv_package.id] && <div style={{width: 33}}>
            <a  style={{margin: 0}}
												title="Upravit"
												className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
												onClick={() => setEdit({open: true, package: tv_package, channels: packages[tv_package.id]})}
											>
												<span className="svg-icon svg-icon-md svg-icon-primary">
													<SVG
														title="Upravit"
														src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
													/>
												</span>
											</a>
            </div>}
          </div>
        )}
      {edit.open && <TvChannelEditDialog onHide={()=>setEdit(false)} channels_init={edit.channels} metadata={offer.metadata} tv_package={edit.package} onSubmit={channels=>updateChannels(channels, edit.package)}/>}
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          onClick={onHide}
          className="btn btn-light btn-elevate"
        >
          Zrušit
        </button>

        <button
          style={{ marginRight: 5 }}
          type="button"
          className="btn btn-primary"
          onClick={() => handleUpdate()}>
          Aktualizovat
        </button>
      </Modal.Footer>
        
    </Modal>

  </>
}
