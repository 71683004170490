import React, { useEffect, useState } from "react";

import { USER_CONTRACTS_URL, COMMIT_CONTRACT_URL } from "../apiUrl";

import axios from 'axios';
import Badge from 'react-bootstrap/Badge'

import Divider from '@material-ui/core/Divider';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Button from 'react-bootstrap/Button';

import VisibilityIcon from '@material-ui/icons/Visibility';

import { CommitDialog } from "./Dialog/CommitDialog";

////<td>{value.registered_date ? value.registered_date : <Button onClick={() => setCommitDialog({ open: true, id: value.id })} size="sm" variant="success">Odsouhlasit</Button>}</td>

	//<td>{value.registered_date ? value.registered_date : <Button size="sm" variant="success">Odsouhlasit</Button>}</td>

export default function Documents() {


	const [commitDialog, setCommitDialog] = useState({ open: false });


	var [contracts, setContracts] = useState({ client: [], items: [] });
	//var [show, setShow]=useState(false);
	//var [invoiceId, setInvoiceId]=useState(false);

	useEffect(() => {
		axios.get(USER_CONTRACTS_URL,).then((response) => {
			setContracts(response.data);
		});

	}, []);


	return <div className="row">

		<div className="col-lg-4">

			<div className="card card-custom gutter-b">

				<div className="card-header border-0 py-5">
					<h3 className="card-title align-items-start flex-column">
						<span className="card-label font-weight-bolder text-dark">
							Smlouvy
						</span>
					</h3>
				</div>

				<div className="card-body py-0">
					<div className="table-responsive">
						<table className="table table-head-custom table-vertical-center">
							<thead>

								<tr>
									<th>Datum vytvoření</th>
									<th>Datum evidence</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{contracts.client.map((value, key) => {
									const url = "https://netis.sattnet.cz/clients/pdfpublic?type=c&key=" + value.access_key;
									return <tr key={key}>
										<td component="th" scope="row" style={{ height: 52 }}>{value.create_date}</td>
										<td>{value.registered_date}</td>
										<td><a target="_blank" href={url}><VisibilityIcon /></a></td>
									</tr>
								})}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>


		<div className="col-lg-8">
			<div className="card card-custom gutter-b">

				<div className="card-header border-0 py-5">
					<h3 className="card-title align-items-start flex-column">
						<span className="card-label font-weight-bolder text-dark">
							Smlouvy - pronájem zařízení
						</span>
					</h3>
				</div>

				<div className="card-body py-0">
					<div className="table-responsive">
						<table className="table table-head-custom table-vertical-center">
							<thead>

								<tr>
									<th>Zařízení</th>
									<th>Sériové číslo</th>
									<th>Datum vytvoření</th>
									<th>Datum evidence</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{contracts.items.map((value, key) => {
									var url = "https://netis.sattnet.cz/clients/pdfpublic?type=i&key=" + value.access_key;
									return <tr key={key}>
										<td component="th" scope="row" style={{ height: 52 }}>{value.item}</td>
										<td>{value.sn}</td>
										<td>{value.create_date}</td>
										<td>{value.registered_date}</td>
										<td><a target="_blank" href={url}><VisibilityIcon /></a></td>
									</tr>
								})}
							</tbody>
						</table>
					</div>
				</div>


			</div>

		</div>
		{commitDialog.open && <CommitDialog id={commitDialog.id} onHide={()=>setCommitDialog({open: false})}/>}
	</div>


}
