import React, {useEffect, useState} from "react";
import {USER_UPDATE_DATA_URL} from "../apiUrl.js";
import axios from 'axios';
import { useSelector } from "react-redux";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import StatusMessage from '../partials/StatusMessage';
import clsx from "clsx";
import { Formik } from "formik";
import ConfirmDialog from "../partials/ConfirmDialog";
 	

export default function EmailForm() {
	
const user=useSelector(state=>state.auth.user);
const [dialogOpen, setDialogOpen]=useState(false);	
const [updateStatus, setUpdateStatus]=useState(false);

const onSubmit=(values, {setSubmitting, setErrors})=>{
        
    axios.put(USER_UPDATE_DATA_URL, {email: values.email})
	    .then((response)=>{
		setSubmitting(false);
		if (response.data.error) {
			setErrors({email: response.data.error});
		    } else {
			setUpdateStatus('success');
		    }
		}).catch(error => {
		    setSubmitting(false);
		    setUpdateStatus('error');
		});
    };



    return (   
	    <Formik
            initialValues={{
              email: user.email,
            }}
            validate={(values) => {
	    const errors = {};
	    
	     if (!values.email) {
                // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
                errors.email = 'toto pole je vyžadováno';
                  
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = 'neplatná emailová adresa'
              }
	    
	    return errors;
	                   
            }}
	    
            onSubmit={onSubmit}
          >
	  {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
	      submitForm
            }) => (<Form noValidate={true}
				autoComplete="off"
				className="kt-form"
				onSubmit={handleSubmit}
			      >
			      {dialogOpen?<ConfirmDialog 
					    handleCancel={()=>setDialogOpen(false)}
					    handleSubmit={()=>{ setDialogOpen(false); submitForm(); }}
					    title={"Aktualizace emailu"}
					    content={<span>Změna emailu se projeví i u přihlašování na tento portál.<br/>Opravdu aktualizovat?</span>}/>:null}
			      <Form.Group as={Row} controlId="formHorizontalEmail">
			    <Form.Label column sm={2}>
				Email
			    </Form.Label>
			    <Col sm={6}>
				<Form.Control name="email" type="email" placeholder="Email" onBlur={handleBlur} className={errors.email?'is-invalid':null}
						    onChange={handleChange}
						    value={values.email}/>
				<Form.Control.Feedback type="invalid">
				    {errors.email}
				</Form.Control.Feedback>		    
			    </Col>
			    </Form.Group>
			    <Form.Group as={Row}>
				<Col sm={{span: 3, offset: 2}}>
				    <Button disabled={isSubmitting} onClick={()=>setDialogOpen(true)} className={clsx(
				    {"kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": isSubmitting})}>
				    Aktualizovat
				    </Button>
				
				</Col>
				<Col sm={7}>
				    {updateStatus=='error'?<StatusMessage state={true} type="error" message="Při aktualizaci došlo k chybě" onClose={()=>setUpdateStatus(false)}/>:null}
				    {updateStatus=='success'?<StatusMessage state={true} type="success" message="Email byl aktualizován" onClose={()=>setUpdateStatus(false)}/>:null}
				</Col>
				
			    </Form.Group>
			    
			</Form>
			)}
	    </Formik>
	)

}
