import React, {useState} from 'react';
import { Modal } from "react-bootstrap";
import { Spinner } from 'react-bootstrap';
import { Badge } from 'react-bootstrap';

export function ConfirmDialog2({ open, onClose, message, action, showSpinner, showStatus }) {

  const [done, setDone]=useState(false);

  function handleSubmit() {
    action();
    if (showSpinner === undefined) onClose();
    //setDone(true);
  }

  return (

    <Modal show={open} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Potvrzení</Modal.Title>
      </Modal.Header>
      <Modal.Body>{showSpinner === true ? 'Prosím čekejte...' : done?'':message}
        {showStatus && <div>

          {showStatus.map((value, key) => {
            let variant="light";
              switch (value) {
                case false:
                  variant="danger";
                  break;
                case true:
                  variant="success";
                  break;
                default:
              }
              return <Badge key={key} variant={variant} style={{marginRight: 5}}>{' '}</Badge>
                
          })}
        </div>
        }
      </Modal.Body>
      <Modal.Footer>
        <div>
          {showSpinner !== true && <button
            type="button"
            onClick={e=>{e.stopPropagation(); onClose()}}
            className="btn btn-light btn-elevate"
          >
            {done?'Storno':'Zrušit'}
          </button>
          }
          <> </>
          {!done && <button
            type="button"
            onClick={e=>{e.stopPropagation(); handleSubmit();}}
            disabled={showSpinner === true}
            className="btn btn-primary btn-elevate"
          >
            {showSpinner === true ? <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            /> :
              'Potvrdit'
            }
          </button>}
        </div>

      </Modal.Footer>
    </Modal>


  );
}