import { actions } from "../app/modules/Auth/_redux/authRedux";

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken }
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }
      
      config.mode='no-cors';
      config.withCredentials=true;
      
      return config;
    },
    err => Promise.reject(err)
  );
  
    axios.interceptors.response.use(function (response) {
    //console.log(response.status);
    return response;
  }, function (error) {
    // Do something with response error
    if (error.response.status==401) {
	store.dispatch(actions.logout());
	console.log('odhlasuji');
    }
    return Promise.reject(error);
  });
}
