import React, {useEffect, useState} from "react";

import {SERVICE_INTERNET_URL} from "../apiUrl";

import axios from 'axios';

import ServiceInternet from "../widgets/ServiceInternet";

import PublicOutlinedIcon from '@material-ui/icons/Public';

import {assigned_type} from './assigned_types';

export default function ServiceInternetDetail({id, setDetailPage}) {

const [service, setService] = useState(0);

useEffect(()=>{
    axios.get(SERVICE_INTERNET_URL+'/'+id, {id: id}).then((response)=>{
		    setService(response.data);
		});

},[])
    if (service==0) return null;
    if (typeof service.error !== 'undefined') return null;
    
    console.log(service);
    return <div className="row">
	    <div className="col-lg-4">
		<ServiceInternet service={service} detail={true} setDetailPage={setDetailPage}/>
	    </div>
	
	
	
	<div className="col-lg-8">
	<div className="card card-custom gutter-b">
		
	<div className="card-header border-0 py-5">
	    <h3 className="card-title align-items-start flex-column">
		<span className="card-label font-weight-bolder text-dark">
		    Přidělené IP adresy
		</span>
	    </h3>
	</div>
		
	<div className="card-body py-0">	
		<div className="table-responsive">
			 <table className="table table-head-custom table-vertical-center">
          <thead>
	  
            <tr>
              <th>IP</th>
              <th>Maska</th>
              <th>Brána</th>
              <th>DNS primání</th>
              <th>DNS sekundární</th>
            </tr>
	    
          </thead>
          <tbody>
             {service.ips.map((ip, key)=>
              <tr key={key}>
                <td>{ip.ip}</td>
                <td>{ip.netmask}</td>
                <td>{ip.gw}</td>
                <td>31.31.224.2</td>
                <td>212.96.160.7</td>
              </tr>
	      )}
          </tbody>
        </table>
		</div>
		</div>
	    </div>
	
	    <div className="card card-custom gutter-b">
	    
	    <div className="card-header border-0 py-5">
	    <h3 className="card-title align-items-start flex-column">
		<span className="card-label font-weight-bolder text-dark">
		    Zařízení
		</span>
	    </h3>
	    </div>
	    
	    <div className="card-body py-0">	
		<div className="table-responsive">
	    
			 <table className="table table-head-custom table-vertical-center">
          <thead>
            <tr>
              <th></th>
              <th>Typ</th>
              <th>Sériové číslo</th>
              <th>MAC / číslo karty</th>
              <th>Typ evidence</th>
            </tr>
          </thead>
          <tbody>
            {service.devices.map((device, key)=>
              <tr key={key}>
                <td><img style={{width: 50}} src={"https://netis.sattnet.cz/images/items/"+device.image}/></td>
                <td>{device.type}</td>
                <td>{device.sn}</td>
                <td>{device.mac}</td>
                <td>{assigned_type(device.assigned_type)}</td>
              </tr>
	      )}

          </tbody>
        </table>
		</div>
	    </div>
	</div>
	
    </div>
   </div>

}
