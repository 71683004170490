import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function AlertDialog({handleCancel, handleSubmit, title, content, buttonCancel, buttonSubmit}) {

  return (     
      <Dialog
	fullWidth={true}
	open={true}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
	      {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            {buttonCancel?buttonCancel:'Storno'}
          </Button>
          <Button onClick={handleSubmit} color="primary" autoFocus>
            {buttonSubmit?buttonSubmit:'OK'}
          </Button>
        </DialogActions>
      </Dialog>
    
  );
}