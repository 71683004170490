/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_helpers";

export function FooterExtended({
  today,
  footerClasses,
  footerContainerClasses,
}) {
  const bgStyle = toAbsoluteUrl("/media/bg/bg-2.jpg");
  return (
    <>
      {/* begin::Footer */}
      <div
        className={`footer ${footerClasses} kt-grid__item bgi-position-center-center bgi-size-cover bgi-no-cover`}
        id="kt_footer"
        style={{ backgroundImage: `url("${bgStyle}")` }}
      >
        {/* begin::Container */}
        <div className={`${footerContainerClasses} py-lg-18 py-8`}>
          {/* begin::Row */}
          <div className="row">
            {/* begin::Col */}
            <div className="col-lg-4 my-lg-">
              <h4 className="text-white pb-3">O portálu</h4>
              <p className="m-0 text-white opacity-45">
                Zkušební verze
              </p>
            </div>
            {/* end::Col */}

            {/* begin::Col */}
            <div className="col-lg-4 my-lg-0 my-5">
              <h4 className="text-white pb-3">Rychlé odkazy</h4>
              <div className="d-flex align-items-center">
                <div className="d-flex flex-column mr-18">
		    <Link to="/services" className="text-white opacity-55 text-hover-primary">
			    Služby
			    </Link>
		    <Link to="/billing" className="text-white opacity-55 text-hover-primary">
			    Vyúčtování
			    </Link>	    
		    <Link to="/documents" className="text-white opacity-55 text-hover-primary">
			    Smlouvy
			    </Link>	    
                </div>
                <div className="d-flex flex-column">
                   <Link to="/user" className="text-white opacity-55 text-hover-primary">
			    Můj profil
		    </Link>
                
                </div>
              </div>
            </div>
            {/* end::Col */}

            {/* begin::Col */}
            {1==2?(<div className="col-lg-4 my-lg-0 my-5">
              <h4 className="text-white pb-3">Get In Touch</h4>
              <form
                className="rounded"
                style={{ backgroundColor: "rgba(0,0,0,.2);" }}
              >
                <div className="input-group p-2 align-items-center">
                  <input
                    type="text"
                    className="form-control rounded-right border-0 bg-transparent text-white opacity-80"
                    placeholder="Enter Your Email"
                  />
                  <div className="input-group-append p-0 mr-1">
                    <button
                      className="btn btn-fh btn-sm  btn-primary px-6 rounded-left"
                      type="button"
                    >
                      Join
                    </button>
                  </div>
                </div>
              </form>
            </div>):null}
            {/* end::Col */}
          </div>
          {/* end::Row */}
        </div>
        {/* end::Container */}

        {/* begin::Container */}
        <div className="separator separator-solid opacity-7"></div>
        {/* end::Container */}

        {/* begin::Container */}
        <div className={`${footerContainerClasses} py-8`}>
          <div className="d-flex align-items-center justify-content-between flex-lg-row flex-column">
            {/* begin::Copyright */}
            <div className="d-flex align-items-center order-lg-1 order-2">
              {1==2?(<img
		style={{width: 100}}
                alt="Logo"
                src={toAbsoluteUrl("/media/company-logos/satt_logo_white.png")}
                className="logo-sticky max-h-35px"
              />):null}
              <span className="text-muted font-weight-bold mx-2">
                {today} ©
              </span>
              <a
                href="https://www.satt.cz"
                rel="noopener noreferrer"
                target="_blank"
                className="text-primary text-hover-primary"
              >
                SATT a.s.
              </a>
            </div>
            {/* end::Copyright */}

            {/* begin::Nav */}
            <div className="d-flex align-items-center order-lg-2 order-1 mb-lg-0 mb-5">
              <a
                href="https://www.satt.cz/dokumenty"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white opacity-55 px-6 text-hover-primary"
              >
                Ke stažení
              </a>
              <a
                href="https://www.satt.cz/kontakt"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white opacity-55 pr-0 text-hover-primary"
              >
                Kontakt
              </a>
            </div>
            {/* end::Nav */}
          </div>
        </div>
        {/* end::Container */}
      </div>
      {/* end:Footer */}
    </>
  );
}
