import React, { useState, useContext, useEffect } from "react";

import { Modal } from "react-bootstrap";
import classes from './ITVPasswordDialog.module.css';
import StatusMessage from '../../partials/StatusMessage';

import axios from "axios";
import { SERVICE_TV_PASSWORD_UPDATE_URL } from "../../apiUrl";

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Formik } from "formik";
import { Spinner } from 'react-bootstrap';

export function ITVPasswordDialog({ onHide, onSuccess, id }) {

  const [updateStatus, setUpdateStatus] = useState(false);
  const [updating, setUpdating]=useState(false);

  const onSubmit = (values) => {
    setUpdating(true);
    setUpdateStatus(false);
     axios.put(SERVICE_TV_PASSWORD_UPDATE_URL, { id: id, password: values.password1 })
      .then((response) => {
        setUpdating(false);
        if (!response.data) {
          setUpdateStatus('error');
        } else {
          onSuccess();
        }
      }).catch(error => {
        setUpdating(false);
        setUpdateStatus('error');
      });
      
  };


  return <Formik
    initialValues={{
      password1: '',
      password2: ''
    }}
    validate={(values) => {
      const errors = {};

      if (!values.password1) {
        // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
        errors.password1 = 'toto pole je vyžadováno';

      } else if (
        !/^(?=.*\d).{8,16}$/.test(values.password1)
      ) {
        errors.password1 = 'heslo musí obsahovat minimálně jednu číslici a musí být v délce 8 - 30 znaků'
      }

      if (!values.password2) {
        errors.password2 = 'toto pole je vyžadováno';
      } else if
        (values.password1 != values.password2) {
        errors.password2 = 'hesla nesouhlasí';
      }

      return errors;

    }}
    onSubmit={onSubmit}

  >
    {({
      values,
      status,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      submitForm
    }) => (

      <Modal
        dialogClassName={classes.ITVPasswordDialog}
        show={true}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title>Interaktivní televize - změna hesla</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Form noValidate={true}
            autoComplete="off"
            className="kt-form"
            onSubmit={handleSubmit}
          >

            <Form.Group as={Row}>
              <Form.Label column sm={4}>
                Nové heslo
              </Form.Label>
              <Col sm={8}>
                <Form.Control name="password1" type="password" placeholder="" onBlur={handleBlur} className={errors.password1 ? 'is-invalid' : null}
                  onChange={handleChange}
                  value={values.password1} />
                <Form.Control.Feedback type="invalid">
                  {errors.password1}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={4}>
                Nové heslo - ověření
              </Form.Label>
              <Col sm={8}>
                <Form.Control name="password2" type="password" placeholder="" onBlur={handleBlur} className={errors.password2 ? 'is-invalid' : null}
                  onChange={handleChange}
                  value={values.password2} />
                <Form.Control.Feedback type="invalid">
                  {errors.password2}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
          </Form>
      {updateStatus==='error' && <div className="invalid-feedback" style={{display: 'block'}}>Při aktualizaci hesla došlo k chybě, zkuste to prosím později.</div>}
        </Modal.Body>
        <Modal.Footer>
          {!updating && <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Zrušit
          </button>}

          <button
            style={{ marginRight: 5 }}
            type="button"
            disabled={updating}
            className="btn btn-primary"
            onClick={() => submitForm()}>
            Aktualizovat
            {updating && <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />}
          </button>
        </Modal.Footer>
      </Modal>
    )}

  </Formik>


}
