import React, { useEffect, useState } from "react";

import Badge from 'react-bootstrap/Badge'

import PublicOutlinedIcon from '@material-ui/icons/Public';
import TvOutlinedIcon from '@material-ui/icons/Tv';
import {ITVPasswordDialog} from '../pages/Dialog/ITVPasswordDialog';
//profile deleting - test branch - test for patch
export default function ServiceTv({ service, detail, setDetailPage }) {

	const [password, setPassword]=useState(false);

	return <div className="card card-custom gutter-b ${!detail?'card-stretch':null}">


		<div className="card-body d-flex flex-column px-0">
			<div></div>
			<div className="flex-grow-1 card-spacer-x mb-6">
				<div className="d-flex mb-6">
					<div className="mr-3" style={{ width: 80, height: 80, borderRadius: 40, backgroundColor: '#f5c700' }}>
						<TvOutlinedIcon style={{ fontSize: 50, margin: 15, color: 'white' }} />
					</div>
					<div>
						<div>
							<span className="font-size-h6 text-dark-75 text-hover-primary font-weight-bolder">
								Televize
							</span>
						</div>

						<div>
							<span>
								{service.is_catv ? <Badge variant="info" style={{ marginTop: 5, marginRight: 5 }}>Kabelovka</Badge> : null}
								{service.is_itv ? <Badge variant="info" style={{ marginTop: 5, marginRight: 5 }}>Interaktivní</Badge> : null}
								{service.is_hbo ? <Badge variant="info" style={{ marginTop: 5, marginRight: 5 }}>HBO GO</Badge> : null}
							</span>
						</div>

						<div>
							{service.active == 1 ?
								<Badge variant="success" style={{ marginTop: 10 }}>Aktivní</Badge>
								:
								<Badge variant="danger" style={{ marginTop: 10 }}>Odpojeno</Badge>
							}
						</div>
					</div>
				</div>


				<div className="d-flex flex-wrap align-items-center justify-content-between w-100">
					<div className="font-weight-bolder">
						Adresa připojení:
					</div>
					<div>
						{service.address_street}
					</div>
				</div>

				<div className="d-flex flex-wrap align-items-center justify-content-between w-100">
					<div className="font-weight-bolder">
						Město / obec:
					</div>
					<div>
						{service.address_city}
					</div>
				</div>

				<div className="d-flex flex-wrap align-items-center justify-content-between w-100">
					<div className="font-weight-bolder">
						Balíčky:
					</div>
					<div>
						{service.packages}
					</div>
				</div>

				<div className="d-flex flex-wrap align-items-center justify-content-between w-100">
					<div className="font-weight-bolder">
						&nbsp;
					</div>
					<div>

					</div>
				</div>



				{detail && service.is_itv && <div className="d-flex flex-wrap align-items-flex-start justify-content-between w-100" style={{marginTop: 15}}>
					<div className="font-weight-bolder">
						iTV přehrávač:
					</div>
					<div style={{textAlign: 'right'}}>
						<a target="_blank" href="https://itv.satt.cz">Internetový prohlížeč</a><br/>
						<a target="_blank" href="https://play.google.com/store/apps/details?id=tv.fournetwork.android.sattitv">Android - Google Play</a><br/>
						<a target="_blank" href="https://apps.apple.com/cz/app/satt-itv/id1200481190?l=cs">Apple - AppStore</a>
						<div style={{width: 150, marginTop: 10}}>
						<a onClick={() => setPassword(true)} className="w-100 btn btn-primary font-weight-bold px-6 py-3">Změnit heslo</a>	
						</div>
						
					</div>
				</div>}


			</div>

			<div className="flex-grow-1 card-spacer-x">

				<div>
					{!detail ?
						<a onClick={() => setDetailPage({ service: 'tv', id: service.id })} className="w-100 btn btn-primary font-weight-bold px-6 py-3">Detail</a>
						: <a onClick={() => setDetailPage(false)} className="w-100 btn btn-primary font-weight-bold px-6 py-3">Zpět na služby</a>}
				</div>
			</div>
            {password && <ITVPasswordDialog id={service.id} onSuccess={()=>setPassword(false)} onHide={()=>setPassword(false)}/>}
		</div>
	</div>;

};

