import React, {useEffect, useState} from "react";

import {USER_SERVICES_URL} from "../apiUrl";

import axios from 'axios';
import Badge from 'react-bootstrap/Badge'

import Divider from '@material-ui/core/Divider';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import ServiceInternet from "../widgets/ServiceInternet";
import ServiceInternetDetail from "./ServiceInternetDetail";

import ServiceTv from "../widgets/ServiceTv";
import ServiceTvDetail from "./ServiceTvDetail";

import ServiceVoip from "../widgets/ServiceVoip";
import ServiceVoipDetail from "./ServiceVoipDetail";

export default function Services() {
	
	var [services, setServices]=useState(false);
	var [detailPage, setDetailPage]=useState(false);
	useEffect(()=>{
		axios.get(USER_SERVICES_URL).then((services)=>{
		    setServices(services.data);
		});

	},[]);
	if (services===false) return null;
	
	if (detailPage!==false) {
	    switch (detailPage.service) {
		case 'internet':
		    return <ServiceInternetDetail id={detailPage.id} setDetailPage={setDetailPage}/>
		case 'tv':
		    return <ServiceTvDetail id={detailPage.id} setDetailPage={setDetailPage}/>
		case 'voip':
		    return <ServiceVoipDetail id={detailPage.id} setDetailPage={setDetailPage}/>
		default:
			return <div/>
	    }
	}
	
	return <div className="row">
	    {services.internet.map((service, key)=>{
		return <div key={key} className="col-lg-4"><ServiceInternet service={service} detail={false} setDetailPage={setDetailPage}/></div>
	    })}
	    
	    {services.tv.map((service, key)=>{
		return <div key={key} className="col-lg-4"><ServiceTv service={service} detail={false} setDetailPage={setDetailPage}/></div>
	    })}
	    
	    {services.voip.map((service, key)=>{
		return <div key={key} className="col-lg-4"><ServiceVoip service={service} detail={false} setDetailPage={setDetailPage}/></div>
	    })}
	</div>
	
}
