import React, {Suspense, lazy} from "react";
import {Redirect, Switch, Route} from "react-router-dom";
import {LayoutSplashScreen, ContentRoute} from "../_metronic/layout";
import {BuilderPage} from "./pages/BuilderPage";
import {MyPage} from "./pages/MyPage";
import {DashboardPage} from "./pages/DashboardPage";

import Services from "./pages/Services";
import Billing from "./pages/Billing";
import Documents from "./pages/Documents";
import User from "./pages/User";
import Support from "./pages/Support";


export default function BasePage() {
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/services"/>
                }
                <ContentRoute path="/dashboard" component={Services}/>                
                <ContentRoute path="/services" component={Services}/>
                <ContentRoute path="/billing" component={Billing}/>
                <ContentRoute path="/documents" component={Documents}/>
		<ContentRoute path="/support" component={Support}/>
		<ContentRoute path="/user" component={User}/>
                
                <Redirect to="error/error-v1"/>
            </Switch>
        </Suspense>
    );
}
