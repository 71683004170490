import React, {useEffect, useState} from "react";

import Badge from 'react-bootstrap/Badge'

import PublicOutlinedIcon from '@material-ui/icons/Public';
import PhoneOutlinedIcon from '@material-ui/icons/Phone';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export default function ModalInvoice({show, setShow, invoiceId}) {
   		    
  const iframe_url='https://netis.sattnet.cz/invoice/public/'+invoiceId;

  const print=()=>{
      var proxyIframe = document.createElement('iframe');
      var body = document.getElementsByTagName('body')[0];
      body.appendChild(proxyIframe);
      proxyIframe.style.width = '100%';
      proxyIframe.style.height = '100%';
      proxyIframe.style.display = 'none';
 
      var contentWindow = proxyIframe.contentWindow;
      contentWindow.document.open();

      contentWindow.document.write('<iframe src="' + iframe_url + '" onload="print();" width="800" height="1122" frameborder="0" marginheight="0" marginwidth="0">');
      contentWindow.document.close();
  }	
	
  return <Modal dialogClassName="invoice_modal" show={show} onHide={()=>{setShow(false);}}>
        <Modal.Header closeButton>
          <Modal.Title>Náhled faktury</Modal.Title>
        </Modal.Header>
	    <Modal.Body>
		    <div style={{width: 600, height: 800, overflow: 'hidden'}}>
			<iframe id="invoice_iframe" scrolling="no" frameBorder="0" src={iframe_url} style={{width: 800, height: 1122, transform: 'scale(0.75)', transformOrigin: 'top left', overflow: 'hidden', border: 0}}/>
		    </div>
	    </Modal.Body>
        <Modal.Footer>
	  <Button variant="secondary" onClick={()=>{print();}}>
            Tisk
          </Button>
          <Button variant="secondary" onClick={()=>{setShow(false);}}>
            Zavřít
          </Button>
        </Modal.Footer>
      </Modal>
	    
};

