import React, {useEffect, useState} from "react";
import {USER_UPDATE_DATA_URL} from "../apiUrl.js";
import axios from 'axios';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import StatusMessage from '../partials/StatusMessage';
import clsx from "clsx";
import { Formik } from "formik";
import ConfirmDialog from "../partials/ConfirmDialog";

export default function PasswordForm() {

const [dialogOpen, setDialogOpen]=useState(false);	
const [updateStatus, setUpdateStatus]=useState(false);
	
const onSubmit=(values, {setSubmitting, setErrors})=>{
        
    axios.put(USER_UPDATE_DATA_URL, {passwordOrigin: values.passwordOrigin, password: values.password1})
	    .then((response)=>{
		setSubmitting(false);
		if (response.data.error) {
			setErrors({passwordOrigin: response.data.error});
		    } else {
			setUpdateStatus('success');
		    }
		}).catch(error => {
		    setSubmitting(false);
		    setUpdateStatus('error');
		});
    };



    return (   
	    <Formik
            initialValues={{
    
            }}
            validate={(values) => {
	    const errors = {};
	    
	     if (!values.passwordOrigin) {
		 errors.passwordOrigin='toto pole je vyžadováno';
	     }
	    
	     if (!values.password1) {
                // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
                errors.password1 = 'toto pole je vyžadováno';
                  
              } else if (
                !/^(?=.*\d).{8,16}$/.test(values.password1)
              ) {
                errors.password1 = 'heslo musí obsahovat minimálně jednu číslici a musí být v délce 8 - 30 znaků'
              }
	      
	      if (!values.password2) {
		    errors.password2 = 'toto pole je vyžadováno';
              } else if 
	       (values.password1 != values.password2) {
		errors.password2 = 'hesla nesouhlasí';
	      }
	    
	    return errors;
	                   
            }}
	    
            onSubmit={onSubmit}
          >
	  {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
	      submitForm
            }) => (
		    <Form noValidate={true}
				autoComplete="off"
				className="kt-form"
				onSubmit={handleSubmit}
			      >
			      {dialogOpen?<ConfirmDialog 
					    handleCancel={()=>setDialogOpen(false)}
					    handleSubmit={()=>{ setDialogOpen(false); submitForm(); }}
					    title={"Aktualizace přístupových údajů"}
					    content={<span>Aktualizovat heslo?</span>}/>:null}
			    <Form.Group as={Row}>
			    <Form.Label column sm={3}>
				Původní heslo
			    </Form.Label>
			    <Col sm={6}>
				<Form.Control name="passwordOrigin" type="password" placeholder="" onBlur={handleBlur} className={errors.passwordOrigin?'is-invalid':null}
						    onChange={handleChange}
						    value={values.passwordOrigin}/>
				<Form.Control.Feedback type="invalid">
				    {errors.passwordOrigin}
				</Form.Control.Feedback>		    
			    </Col>
			    </Form.Group>
			    <Form.Group as={Row}>
			    <Form.Label column sm={3}>
				Nové heslo
			    </Form.Label>
			    <Col sm={6}>
				<Form.Control name="password1" type="password" placeholder="" onBlur={handleBlur} className={errors.password1?'is-invalid':null}
						    onChange={handleChange}
						    value={values.password1}/>
				<Form.Control.Feedback type="invalid">
				    {errors.password1}
				</Form.Control.Feedback>		    
			    </Col>
			    </Form.Group>
			     <Form.Group as={Row}>
			    <Form.Label column sm={3}>
				Nové heslo
			    </Form.Label>
			    <Col sm={6}>
				<Form.Control name="password2" type="password" placeholder="" onBlur={handleBlur} className={errors.password2?'is-invalid':null}
						    onChange={handleChange}
						    value={values.password2}/>
				<Form.Control.Feedback type="invalid">
				    {errors.password2}
				</Form.Control.Feedback>		    
			    </Col>
			    </Form.Group>
			    <Form.Group as={Row}>
				<Col sm={{span: 3, offset: 3}}>
				    <Button disabled={isSubmitting} onClick={()=>setDialogOpen(true)} className={clsx(
				    {"kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": isSubmitting})}>
				    Aktualizovat
				    </Button>
				
				</Col>
				<Col sm={6}>
				{updateStatus=='error'?<StatusMessage state={true} type="error" message="Při aktualizaci došlo k chybě" onClose={()=>setUpdateStatus(false)}/>:null}
				{updateStatus=='success'?<StatusMessage state={true} type="success" message="Heslo bylo aktualizováno" onClose={()=>setUpdateStatus(false)}/>:null}
				
				</Col>
				
			    </Form.Group>
			</Form>
		)}
	    </Formik>
	)

}
